import axios from 'axios';
import elasticTracking from './elastic-tracking.js';

class tracking {
    constructor(trackingID, siteUrl, siteSlug) {
        this.ga_tracking_code = trackingID;
        this.site_url = siteUrl;
        this.site_slug = siteSlug;
        this.client_id = null;
        this.max_events = 20;
        this.time = 600;
        this.events_pending = [];
        this.currentSearch = [];
        this.counter = [];
        this.show_stats = false;
        this.printDealerSearch = [];
        this.currentHomeSlider = [];
        this.currentArticleSection = [];
        this.currentDealerHomeSlider = [];
        this.currentAgenciesTopMenu = [];
        this.currentAgenentsTopMenu = [];
        this.currentAuthorArticleSection = [];
        this.axios = true;
        this.service = axios.create({
            withCredentials: false, // This is the default
        });
        this.elasticTracking = new elasticTracking(this.site_slug);

        this.loadAnalytics(
            window,
            document,
            'script',
            '//www.google-analytics.com/analytics.js',
            'ga'
        );
        ga('create', this.ga_tracking_code, 'auto');
        let here = this;
        ga(function (tracker) {
            here.client_id = tracker.get('clientId');
            console.log('GA READY', here.ga_tracking_code, here.client_id);
            here.init();
        });
    }
    loadAnalytics(i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        (i[r] =
            i[r] ||
            function () {
                (i[r].q = i[r].q || []).push(arguments);
            }),
            (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    }
    init() {
        this.process_events();
    }
    serialize(obj) {
        var str = [];

        for (var p in obj) {
            if (obj.hasOwnProperty(p)) {
                str.push(
                    encodeURIComponent(p) + '=' + encodeURIComponent(obj[p])
                );
            }
        }
        return str.join('&');
    }
    add_event(eventsParams) {
        this.events_pending.push(eventsParams);
    }
    process_events() {
        var tracking_codes = [];
        tracking_codes.push(this.ga_tracking_code);

        var process_list = this.events_pending.slice(0, this.max_events);
        this.events_pending = this.events_pending.splice(this.max_events);
        var here = this;
        var data = [];

        process_list.forEach(function (eventParam, index) {
            for (var x = 0; x < tracking_codes.length; x++) {
                var tracking_code = tracking_codes[x];
                var batch_params = {
                    v: 1,
                    tid: tracking_code,
                    cid: here.client_id,
                    t: 'event',
                    ea: eventParam.eventAction || null,
                    ec: eventParam.eventCategory || null,
                    el: eventParam.eventLabel || null,
                    ev: eventParam.eventValue || 1,
                };

                if (eventParam.customDimensions) {
                    for (
                        var y = 1;
                        y <= eventParam.customDimensions.length;
                        y++
                    ) {
                        batch_params['cd' + y] = JSON.stringify(
                            eventParam.customDimensions[y - 1]
                        );
                    }
                }

                data.push(here.serialize(batch_params));
            }
        });

        if (this.axios) {
            var settings = {
                url: 'https://www.google-analytics.com/batch',
                data: data.join('\n\r'),
            };

            if (data.length > 0) {
                this.service
                    .post(settings.url, settings.data)
                    .then(response => {});
            }
        } else {
            var settings = {
                async: true,
                crossDomain: true,
                url: 'https://www.google-analytics.com/batch',
                method: 'POST',
                data: data.join('\n\r'),
            };

            if (data.length > 0) {
                jQuery.ajax(settings).done(response => {});
            }
        }

        setTimeout(function () {
            here.process_events();
        }, here.time);
    }
    getValueFromQuerystring(value) {
        let urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(value);
    }
    QueryStringToJSON() {
        var pairs = location.search.slice(1).split('&');
        var result = {};
        pairs.forEach(function (pair) {
            if (pair != '') {
                pair = pair.split('=');
                result[pair[0]] = decodeURIComponent(pair[1] || '');
            }
        });

        if (Object.keys(result).length > 0) {
            return result;
        }
        return null;
    }
    sendpageview(page) {
        if (page === null || page === undefined) {
            throw 'You are missing the page parameter.';
        }

        ga('send', 'pageview', page);

        // ElasticTracking
        let label = this.QueryStringToJSON();
        label = this.elasticTracking.normalizeLabel(label);

        this.elasticTracking.track({
            action: 'pageview',
            value: 1,
            label: label,
            props: {
                page: page,
            },
            origin: 'search',
            category: 'cars',
            domain: this.site_url,
            source: this.site_slug,
        });
    }
    sendevent(action, payload) {
        if (action === null || action === undefined) {
            throw 'You are missing the action parameter.';
        }
        if (typeof payload !== 'object' || payload === null) {
            throw 'You are missing the payload parameter or is different than an object.';
        }
        if (
            payload.event_category === undefined ||
            payload.event_label === undefined
        ) {
            throw 'You are missing some important information in the payload, please check.';
        }
        // gtag('event', action, payload);
        ga(
            'send',
            'event',
            payload.event_category,
            action,
            payload.event_label,
            1,
            {
                dimension1: payload.dealer_id,
                dimension2: payload.car_id,
                dimension3: payload.context,
                dimension4: payload.context_id,
            }
        );

        // ElasticTracking
        let label = JSON.parse(payload.event_label);
        label = this.elasticTracking.normalizeLabel(label);

        this.elasticTracking.track({
            action: action,
            value: 1,
            label: label,
            props: {
                searchtype: payload.searchtype || undefined,
                context: payload.context || undefined,
                context_id: payload.context_id || undefined,
            },
            origin: payload.context,
            category: payload.event_category,
            domain: this.site_url,
            source: this.site_slug,
        });
    }
    sendbatchevent(action, payload) {
        // cuando se usa este metodo, verificar que se envian las 4 customDimensions
        // y en el orden correcto
        if (action === null || action === undefined) {
            throw 'You are missing the action parameter.';
        }
        if (typeof payload !== 'object' || payload === null) {
            throw 'You are missing the payload parameter or is different than an object.';
        }
        if (
            payload.event_category === undefined ||
            payload.event_label === undefined
        ) {
            throw 'You are missing some important information in the payload, please check.';
        }

        this.add_event({
            eventCategory: payload.event_category, //required
            eventAction: action, //required
            eventLabel: payload.event_label || null,
            eventValue: 1,
            customDimensions: [
                payload.dealer_id,
                payload.car_id,
                payload.context,
                payload.context_id,
            ],
        });

        // ElasticTracking
        // debugger;
        let label = JSON.parse(payload.event_label);
        label = this.elasticTracking.normalizeLabel(label);

        this.elasticTracking.track({
            action: action,
            value: 1,
            label: label,
            props: {
                searchtype: payload.searchtype || undefined,
                context: payload.context || undefined,
                context_id: payload.context_id || undefined,
            },
            origin: payload.context,
            category: payload.event_category,
            domain: this.site_url,
            source: this.site_slug,
        });
    }
}

export default tracking;
