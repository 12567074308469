import apiClient from './axiosService';

class carService extends apiClient {
    getCarExtraInfo(carId, callback) {
        return new Promise((resolve, reject) => {
            let carExtraInfo = this.storage.get('carInfo-' + carId);
            if (carExtraInfo == undefined) {
                this.service.get('/api/v2/cars/info/' + carId)
                    .then((response) => {
                        this.storage.set('carInfo-' + carId, response.data);
                        response.data.callback = callback;
                        resolve(response.data);
                    }).catch(err => {
                        if (err.response.status===404) {
                            console.log('Car not found: ' + carId);
                        } else {
                            console.log(err);                            
                        }
                    })
            } else {
                resolve(carExtraInfo);
            }
        })
    }
    getCars(filters, cacheKey) {
        return new Promise((resolve, reject) => {
            let cars = this.storage.get(cacheKey);
            if (cars == undefined) {
                let url = '/api/v2/cars/search?aroundRadius=all&size=500&sort=random&' + filters
                this.service.get(url)
                    .then((response) => {
                        let data = {
                            'hits': response.data.data,
                            'nbHits': response.data.total
                        }
                        this.storage.set(cacheKey, data);
                        resolve(data);
                    }).catch(err => {
                        console.log(err);
                    })
            } else {
                resolve(cars);
            }
        });
    }
}

export default carService