<template>
    <div :data-id="item.id" class="slider-card-container">
        <div :data-id="item.id" class="slider-card">
            <div class="first-subtitle ws-text-white ws-bg-black ws-font-bold ws-px-2 ws-py-1 ws-uppercase ws-text-sm">{{ item.year }} {{ item.make }} {{ item.model }}</div>
            <!-- IMAGE -->
            <a :href="`${item.url}?utm_source=${utm_source}`" target="_blank" rel="noopener noreferrer" @click="trackClick(item)">
                <car-images
                    :url="urlImage"
                    :title="item.title"
                />
            </a>
            <!-- VIN AND STOCK -->
            <div class="vin-stock ws-border-gray-100 ws-border-b ws-text-xs ws-uppercase ws-flex" v-if="item.vin || item.stock">
                <span class="ws-p-2 ws-pr-0 ws-mr-2" v-if="item.stock" >STOCK: <span class="data-content">{{ item.stock }}</span></span>
                <span class="ws-p-2 ws-pr-0" v-if="item.vin" >VIN: <span class="data-content">{{ item.vin }}</span> </span>
            </div>
            <!-- TITLE -->
            <h2 class="title ws-p-2 ws-border-b ws-border-gray-100">
                <a class="ws-text-black ws-no-underline hover:ws-text-grey-darker ws-text-base" :href="`${item.url}?utm_source=${utm_source}`" target="_blank" rel="noopener noreferrer" @click="trackClick(item)">
                    {{ item.title }}
                </a>
            </h2>
            <!-- PRICE AND KM -->
            <template v-if="item.prices">
                <div class="ws-flex">
                    <div class="ws-flex ws-w-1/2 ws-flex-col ws-p-2" v-if="item.prices.price">
                        <div class="box-title">Dealer Price</div>
                        <div class="box-content prices">{{ item.prices.price | price }}</div>
                    </div>
                    <div class="ws-flex ws-w-1/2 ws-flex-col ws-p-2" v-if="distance_measure=='kilometer'">
                        <div class="box-title">Kilometres</div>
                        <div class="box-content">{{ item.kilometer }}</div>
                    </div>
                    <div class="ws-flex ws-w-1/2 ws-flex-col ws-p-2" v-if="distance_measure=='mile'">
                        <div class="box-title">Miles</div>
                        <div class="box-content">{{ item.miles }}</div>
                    </div>
                </div>
                <div class="extra-info ws-p-2">
                    <span v-if="item.prices.msrp_price">MSRP: {{ item.prices.msrp_price | price }}</span>
                    <span v-if="item.prices.discount_price">Save: {{ item.prices.discount_price | price }}</span>
                </div>
            </template>
            <!-- DEALER -->
            <div class="dealer-info ws-flex ws-cursor-pointer ws-border-gray-100 ws-border-b" @click="gotodealerpage(item, 'slider', 'slider_id', tracking)" v-if="showDealer">
                <div class="ws-w-1/2 ws-h-12 ws-overflow-hidden ws-bg-no-repeat ws-bg-contain ws-bg-center ws-bg-white" :style="'background-image: url(' + showDealer + ')'" >
                </div>
                <div class="dealer-phone ws-text-sm ws-w-1/2 ws-bg-gray-100 ws-flex ws-justify-center ws-flex-col ws-p-2 ws-h-12 ws-overflow-hidden">
                    <p class="ws-text-black ws-text-center">Start Saving!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {price} from '../../../filters/filters'
import {gotodealerpage} from '../../modules/dealers'
import carImages from '../carImages.vue'

export default {
    computed: {
        showDealer() {
            if (this.item.dealer.logo && this.item.dealer.logo!='') {
                return this.item.dealer.logo
            }
            if (this.item._dealer && this.item._dealer.logo['medium-size']!='') {
                return this.item._dealer.logo['medium-size']
            }
            return false
        },
        urlImage(){
            if (this.item.image && this.item.image!='') {
                return this.item.image
            }
            if(this.item.images && this.item.images.length>0 && this.item.images[0]['medium-size']) {
                return this.item.images[0]['medium-size']
            }
            return '/images/car.default.png'
        }
    },
    components: {
        'car-images': carImages,
    },
    props: {
        item: {
            required: true,
            type: Object
        },
        utm_source: {
            required: false,
            type: String
        },
        tracking: {
            required: true
        },
        distance_measure: {
            required: false,
            default: 'mile'
        }
        
    },
    data() {
        return {
        }
    },
    filters: {
        price
    },
    methods: {
        gotodealerpage,
        trackClick(item) {
            this.tracking.sendevent('click', {
                'event_category': 'car',
                'event_label': JSON.stringify(item),
                'dealer_id': item.dealer.id,
                'car_id': item.id,
                'context': 'slider',
                'context_id': 'slider_id',
            });
        },
    },
}
</script>
