<template>
    <div class="ws-my-12" v-if="hasItems">
        <h3
            class="home-component-title slider-title ws-cursor-pointer ws-flex ws-items-center ws-flex-col ws-gap-3 sm:ws-flex-row"
            @click="gotosearch()"
        >
            {{ title }}
            <span v-if="totalItems && totalItemsType == '1'"
                >({{ totalItems }})</span
            ><span
                class="ws-bg-gray-100 ws-text-sm ws-rounded-full px-2 py-1 ws-ml-3"
                v-if="totalItems && totalItemsType == '2'"
                >{{ totalItems }} Units</span
            >
        </h3>
        <slick ref="slick" :options="slickOptions">
            <component
                :groupsConfig="groupsConfig"
                :is="itemType"
                :car_default_placeholder="car_default_placeholder"
                :tracking_domain="tracking_domain"
                :tracking="tracking"
                :ga_tracking="ga_tracking"
                :utm_source="utm_source"
                :distance_measure="distance_measure"
                v-for="item in hits"
                :key="item.renderKey"
                :item="item"
            ></component>
        </slick>
    </div>
</template>

<script>
import Vue from 'vue';
import Vue2Storage from 'vue2-storage';
import { cacheConfig, apiBaseUrl } from '../../config';
Vue.use(Vue2Storage, cacheConfig);

import tracking from '../modules/tracking';
import sliderService from '../../services/sliderService';
import carService from '../../services/carService';
import serviceSpecialService from '../../services/serviceSpecialService';
import Slick from 'vue-slick';

import carViewSlider1 from './carViewComponents/carViewSlider1';
import carViewSlider2 from './carViewComponents/carViewSlider2';
import carViewSlider3 from './carViewComponents/carViewSlider3';
import carViewSlider4 from './carViewComponents/carViewSlider4';
import serviceViewSlider from './serviceViewSlider';

export default {
    created() {
        this.getInfo();
    },
    methods: {
        gotosearch() {
            window.location = this.titleLink;
        },
        setupSliderOptions(response) {
            this.title = response.title;
            this.type = response.type;
            this.utm_source = response.site.utm_source;
            this.ga_tracking = response.site.ga_tracking_id;
            this.distance_measure = response.site.configs.distance_measure;
            this.tracking_domain = response.site.configs.tracking_domain;
            this.car_default_placeholder =
                response.site.configs.car_default_placeholder || null;
            this.site_url = response.site.url;
            this.site_slug = response.site.slug;
            this.custom_css = response.custom_css;
            this.groupsConfig = response.groupsConfig;
            this.limit_items = response.limit;
            this.slickOptions.slidesToShow = response.slides_to_show;
            this.slickOptions.slidesToScroll = response.slides_to_scroll;
            this.slickOptions.responsive[0].settings.slidesToShow =
                response.slides_to_show - 1 > 0
                    ? response.slides_to_show - 1
                    : 1;
            this.slickOptions.responsive[0].settings.slidesToScroll =
                response.slides_to_scroll - 1 > 0
                    ? response.slides_to_scroll - 1
                    : 1;
            this.slickOptions.responsive[1].settings.slidesToShow =
                response.slides_to_show - 2 > 0
                    ? response.slides_to_show - 2
                    : 1;
            this.slickOptions.responsive[1].settings.slidesToScroll =
                response.slides_to_scroll - 2 > 0
                    ? response.slides_to_scroll - 2
                    : 1;
            this.parseTitleLink(response.filters, response.site.url);
            this.defineFilters(response.filters, this.facetFilters);
        },
        parseTitleLink(filters, site_url) {
            let sin = {
                make: 'make',
                year: 'year',
                color: 'color',
                model: 'model',
                price: 'price',
                bodyStyle: 'bodyStyle',
                'type.name': 'type',
                'group.name': 'group',
                'dealer.name': 'dealer',
                'region.name': 'region',
            };
            let searchString = '';
            if (filters !== null) {
                for (const key in sin) {
                    if (filters[key] !== undefined) {
                        if (
                            ['kilometer', 'mile', 'price', 'year'].includes(
                                sin[key]
                            )
                        ) {
                            searchString +=
                                '&' +
                                sin[key] +
                                '=' +
                                filters[key].replace(' TO ', '-');
                        } else {
                            searchString +=
                                '&' +
                                sin[key] +
                                '=' +
                                filters[key].toLowerCase();
                        }
                    }
                }
            }
            searchString = searchString.trim('&');
            if (this.remote) {
                this.titleLink = `${site_url}/search?${searchString}`;
            } else {
                this.titleLink = `/search?${searchString}`;
            }
        },
        shuffle(o) {
            for (
                var j, x, i = o.length;
                i;
                j = Math.floor(Math.random() * i),
                    x = o[--i],
                    o[i] = o[j],
                    o[j] = x
            );
            return o;
        },
        loadcssfile(filename) {
            if (!document.querySelector("[href='" + filename + "']")) {
                var fileref = document.createElement('link');
                fileref.setAttribute('rel', 'stylesheet');
                fileref.setAttribute('type', 'text/css');
                fileref.setAttribute('href', filename);
                if (typeof fileref != 'undefined') {
                    document
                        .getElementsByTagName('head')[0]
                        .appendChild(fileref);
                }
            }
        },
        getInfo() {
            this.sliderService.getSliderConfig(this.sliderId).then(response => {
                this.setupSliderOptions(response);
                this.loadcssfile(apiBaseUrl + '/css/webcomponents/sliders.css');
                this.loadcssfile(this.custom_css);

                // El analytics es el custom del slider o el general del sitio
                let analytics_tracking_id =
                    response.custom_analytics != ''
                        ? response.custom_analytics
                        : response.site.ga_tracking_id;
                this.tracking = new tracking(
                    analytics_tracking_id,
                    this.site_url,
                    this.site_slug
                );

                let filters = this.facetFilters.join('&');

                this.carService
                    .getCars(filters, 'sliderItems-' + this.sliderId)
                    .then(_hits => {
                        this.totalItems = _hits.nbHits;
                        let _tmpHits = this.shuffle(_hits.hits);
                        this.hits = _tmpHits.slice(0, this.limit_items);
                        this.trackSliderDealersPageViews();
                        this.trackSliderCarsImpressions();
                        this.reInit();
                    })
                    .catch(function (err) {
                        console.log(err);
                        console.log(err.debugData);
                    });
            });
        },
        trackSliderCarsImpressions() {
            this.hits.forEach(item => {
                item.context = 'slider';
                item.context_id = this.sliderId;
                const data = {
                    event_category: this.type === 'cars' ? 'car' : 'service',
                    event_label: JSON.stringify(item),
                    dealer_id: item.dealer.id,
                    car_id: item.id,
                    context: 'slider',
                    context_id: this.sliderId,
                };
                this.tracking.sendbatchevent('impression', data);
            });
        },
        trackSliderDealersPageViews() {
            var dealers_ids = [];
            this.hits.forEach(item => {
                var dealer_id = item.dealer.id;
                dealers_ids.push(dealer_id);
            });
            var uniq_dealers_ids = [...new Set(dealers_ids)];
            uniq_dealers_ids.forEach(dealer_id => {
                this.tracking.sendbatchevent('impression', {
                    event_category: 'dealer',
                    event_label: JSON.stringify({
                        id: dealer_id,
                    }),
                    car_id: null,
                    dealer_id: dealer_id,
                    context: 'slider',
                    context_id: this.sliderId,
                });
            });
        },
        defineFilters(paramToParse, filterType) {
            if (paramToParse !== undefined && paramToParse !== null) {
                if (Object.keys(paramToParse).length > 0) {
                    for (let [key, value] of Object.entries(paramToParse)) {
                        if (
                            ['kilometer', 'mile', 'price', 'year'].includes(key)
                        ) {
                            filterType.push(
                                this.parseInitialSearchParamArray(value, key)
                            );
                        } else {
                            filterType.push(
                                this.parseInitialSearchParamArray(value, key)
                            );
                        }
                    }
                }
            }
        },
        parseInitialSearchParamArray(value, facet) {
            value = value.replace(' TO ', '-');
            if (typeof value === 'string' || value instanceof String) {
                return facet + '=' + value.toLowerCase();
            }
            if (value instanceof Array) {
                let tmp_array = [];
                value.forEach(o => {
                    tmp_array.push(facet + '=' + o);
                });
                return tmp_array;
            }
        },
        reInit() {
            this.hasItems = this.hits.length > 0;
            if (this.$refs.slick !== undefined) {
                let currIndex = this.$refs.slick.currentSlide();
                this.$refs.slick.destroy();
                this.$nextTick(() => {
                    this.$refs.slick.create();
                    this.$refs.slick.goTo(currIndex, true);
                });
            }
        },
    },
    components: {
        'car-view-slider1': carViewSlider1,
        'car-view-slider2': carViewSlider2,
        'car-view-slider3': carViewSlider3,
        'car-view-slider4': carViewSlider4,
        'service-view-slider': serviceViewSlider,
        Slick,
    },
    computed: {
        itemType() {
            if (this.type === 'cars') {
                return 'car-view-slider' + this.templateId;
            }
            if (this.type === 'services') {
                return 'service-view-slider';
            }
        },
    },
    data() {
        return {
            title: '',
            totalItems: null,
            titleLink: null,
            type: null,
            carService: new carService(this.siteId),
            sliderService: new sliderService(this.siteId),
            serviceSpecialService: new serviceSpecialService(this.siteId),
            tracking: null,
            utm_source: null,
            distance_measure: null,
            tracking_domain: null,
            car_default_placeholder: null,
            site_url: null,
            site_slug: null,
            custom_css: null,
            groupsConfig: null,
            limit_items: 50,
            hasItems: false,
            searchClient: null,
            searchIndex: null,
            facetFilters: [],
            numericFilters: [],
            hits: {},
            slickOptions: {
                slidesToScroll: 4,
                slidesToShow: 4,
                adaptiveHeight: true,
                infinite: true,
                dots: this.dots,
                arrows: !this.dots,
                autoplay: true,
                autoplaySpeed: 7000000,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false,
                            arrows: true,
                        },
                    },
                ],
            },
        };
    },
    props: {
        sliderId: {
            type: Number,
            required: true,
        },
        siteId: {
            type: String,
            required: true,
        },
        templateId: {
            type: String,
            required: false,
            default: '1',
        },
        totalItemsType: {
            type: String,
            required: false,
            default: '1',
        },
        // type: {
        //     type: String,
        //     required: false,
        //     default: 'cars',
        // },
        dots: {
            type: Boolean,
            default: true,
            required: false,
        },
        remote: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
};
</script>
