import axios from 'axios'
import Vue from 'vue';
import Vue2Storage from 'vue2-storage';
import { cacheConfig, apiBaseUrl } from '../config'
Vue.use(Vue2Storage, cacheConfig)

const apiClient = class {
    constructor(siteID) {
        this.storage = Vue.$storage
        this.service = axios.create({
            baseURL: apiBaseUrl,
            withCredentials: false, // This is the default
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'wehaa-site': siteID
            }
        })
    }
}

export default apiClient