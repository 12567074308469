<template>
    <div :data-id="item.id" class="slider-card-container">
        <div :data-id="item.id" class="slider-card">
            <div class="first-subtitle ws-text-white ws-bg-black ws-font-bold ws-px-2 ws-py-1 ws-uppercase ws-text-sm">{{ item.year }} {{ item.make }}</div>
            <div class="second-subtitle ws-text-black ws-bg-white ws-font-bold ws-px-2 ws-py-1 ws-uppercase ws-text-lg">{{ item.model }}</div>
            <!-- IMAGE -->
            <a :href="`${item.url}?utm_source=${utm_source}`" target="_blank" rel="noopener noreferrer" @click="trackClick(item)">
                <car-images
                    :url="urlImage"
                    :title="item.title"
                />
            </a>
            <!-- DEALER -->
            <div class="dealer-info ws-flex ws-cursor-pointer ws-border-gray-100 ws-border-b" @click="gotodealerpage(item, 'slider', 'slider_id', tracking)" v-if="showDealer">
                <div class="ws-w-1/2 ws-h-12 ws-overflow-hidden ws-bg-no-repeat ws-bg-contain ws-bg-center ws-bg-white" :style="'background-image: url(' + showDealer + ')'" >
                </div>
                <div class="dealer-phone ws-text-sm ws-w-1/2 ws-bg-gray-100 ws-flex ws-justify-center ws-flex-col ws-p-2 ws-h-12 overflow-hidden">
                    <p class="ws-text-black">Call Us</p>
                    <p class="ws-text-black ws-text-xs ws-font-bold" v-if="item._dealer && item._dealer.phones">{{ item._dealer.phones[0].phone }}</p>
                </div>
            </div>
            <!-- VIN AND STOCK -->
            <div class="vin-stock ws-border-gray-100 ws-border-b ws-text-xs ws-uppercase ws-flex" v-if="item.vin || item.stock">
                <span class="ws-overflow-x-hidden ws-p-2 ws-w-2/3 ws-pr-0" v-if="item.vin" >VIN<br><b>{{ item.vin }}</b> </span>
                <span class="ws-overflow-x-hidden ws-p-2 ws-w-1/3 ws-pr-0 ws-mr-2" v-if="item.stock" >STOCK<br><b>{{ item.stock }}</b></span>
            </div>
            <h2 class="title ws-min-h-14 ws-p-2 ws-border-b ws-border-gray-100">
                <a class="ws-text-black ws-no-underline hover:ws-text-grey-darker ws-text-base" :href="`${item.url}?utm_source=${utm_source}`" target="_blank" rel="noopener noreferrer" @click="trackClick(item)">
                    {{ item.title }}
                </a>
            </h2>
            <template v-if="item.prices">
                <div class="prices ws-p-2 ws-border-b ws-border-gray-100 ws-flex ws-justify-between ws-items-center" v-if="item.prices.price">
                    <span class="ws-text-xs">Our Price</span>
                    <span class="ws-font-bold ws-text-lg">{{ item.prices.price | price }}</span>
                </div>
                <div class="prices ws-p-2 ws-border-b ws-border-gray-100 ws-flex ws-justify-between ws-items-center" v-if="item.prices.msrp_price">
                    <span class="ws-text-xs">MSRP</span>
                    <span>{{ item.prices.msrp_price | price }}</span>
                </div>
                <div class="prices ws-p-2 ws-border-b ws-border-gray-100 ws-flex ws-justify-between ws-items-center" v-if="item.prices.discount_price">
                    <span class="ws-text-xs">Dealer Discount</span>
                    <span>{{ item.prices.discount_price | price }}</span>
                </div>
                <div class="prices ws-p-2 ws-border-b ws-border-gray-100 ws-flex ws-justify-between ws-items-center" v-if="item.prices.lease_price">
                    <span class="ws-text-xs">Lease For</span>
                    <span class="ws-pl-3 ws-text-xs">{{ item.prices.lease_price | price }}<template v-if="item.prices.lease_explanation" > / {{ item.prices.lease_explanation}}</template></span>
                </div>
                <div class="prices ws-p-2 ws-border-gray-100 ws-flex ws-justify-between ws-items-center" v-if="item.prices.finance_price">
                    <span class="ws-text-xs">Finance For</span>
                    <span class="ws-pl-3 ws-text-xs">{{ item.prices.finance_price | price }}<template v-if="item.prices.finance_explanation" > / {{ item.prices.finance_explanation}}</template></span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {price} from '../../../filters/filters'
import {gotodealerpage} from '../../modules/dealers'
import carImages from '../carImages.vue'

export default {
    computed: {
        showDealer() {
            if (this.item.dealer.logo && this.item.dealer.logo!='') {
                return this.item.dealer.logo
            }
            if (this.item._dealer && this.item._dealer.logo['medium-size']!='') {
                return this.item._dealer.logo['medium-size']
            }
            return false
        },
        urlImage(){
            if (this.item.image && this.item.image!='') {
                return this.item.image
            }
            if(this.item.images && this.item.images.length>0 && this.item.images[0]['medium-size']) {
                return this.item.images[0]['medium-size']
            }
            return '/images/car.default.png'
        }
    },
    components: {
        'car-images': carImages,
    },
    props: {
        item: {
            required: true,
            type: Object
        },
        utm_source: {
            required: false,
            type: String
        },
        tracking: {
            required: true
        }
        
    },
    data() {
        return {
        }
    },
    filters: {
        price
    },
    methods: {
        gotodealerpage,
        trackClick(item) {
            this.tracking.sendevent('click', {
                'event_category': 'car',
                'event_label': JSON.stringify(item),
                'dealer_id': item.dealer.id,
                'car_id': item.id,
                'context': 'slider',
                'context_id': 'slider_id',
            });
        },
    },
}
</script>
