import axios from 'axios';

class elasticTracking {
    constructor(siteSlug) {
        this.site_slug = siteSlug;
        this.elasticTrackingService = {
            url: 'https://analytics-prd.aws.wehaa.net',
            endpoints: {
                postCreateTracking: '/api/v1/trackings'
            }
        };
        this.axios = true;
        this.service = axios.create({
            withCredentials: false // This is the default
        });
    }

    track(eventsParams) {
        // if (
        //   eventsParams.action == "click"
        //   //   &&
        //   //   eventsParams.origin == "slider" &&
        //   //   eventsParams.category == "car"
        // ) {
        //   console.table(eventsParams);
        // }
        this.service
            .post(
                this.elasticTrackingService.url + this.elasticTrackingService.endpoints.postCreateTracking,
                eventsParams,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Tenant': this.site_slug,
                        'X-cat': 'dealers'
                    }
                }
            )
            .then(response => {
                //console.log(response);
            });
    }

    normalizeLabel(label) {
        // si no es un objeto
        if (label?.constructor !== Object) {
            label = {
                default: label
            };
            return label;
        } else {
            // label por defecto
            var normalizeLabel = {
                default: label.default || undefined,
                car_id: label.car_id || undefined,
                dealer_id: label.dealer_id || undefined,
                dealer:
                    label?.dealer?.constructor === Object
                        ? label.dealer.id
                        : label?.dealer !== undefined
                        ? label.dealer
                        : undefined,

                aroundRadius: label.aroundRadius || undefined,
                group:
                    label?.group?.constructor === Object
                        ? label.group.name
                        : label?.group !== undefined
                        ? label.group
                        : undefined,
                make: label.make || undefined,
                paper:
                    label?.paper?.constructor === Object
                        ? label.paper.name
                        : label?.paper !== undefined
                        ? label.paper
                        : undefined,
                size: label.size || undefined,
                color: label.color || undefined,
                year: label.year != undefined ? String(label.year) : undefined,
                discount_price: label.discount_price || undefined,
                geoloc: label?.geoloc?.constructor === Object ? label.geoloc : undefined,
                title: label.title || undefined,
                type:
                    label?.type?.constructor === Object
                        ? label.type.name
                        : label?.type !== undefined
                        ? label.type
                        : undefined,
                content: label.content || undefined,
                miles: label.miles || undefined,
                msrp_price: label.msrp_price || undefined,
                engine: label.engine || undefined,
                price: label.price != undefined ? String(label.price) : undefined,
                model: label.model || undefined,
                vin: label.vin || undefined,
                by_owner_email: label.by_owner_email || undefined,
                id: label.id || undefined,
                stock: label.stock || undefined,
                make: label.make || undefined,
                hasPrice: label.hasPrice || undefined,
                image: label.image || undefined,
                certifiedPreowned: label.certifiedPreowned || undefined,
                active: label.active || undefined,
                bodyStyle: label.bodyStyle || undefined,
                diff: label.diff || undefined,
                url: label.url || undefined,
                by_owner_phone: label.by_owner_phone || undefined,
                kilometer: label.kilometer != undefined ? String(label.kilometer) : undefined,
                location:
                    label?.location?.constructor === Object
                        ? label.location.name
                        : label?.location !== undefined
                        ? label.location
                        : undefined,
                region:
                    label?.region?.constructor === Object
                        ? label.region.name
                        : label?.region !== undefined
                        ? label.region
                        : undefined,
                hasKilometer: label.hasKilometer || undefined,
                by_owner: label.by_owner || undefined,
                distance: Number.isInteger(label.distance) ? label.distance : undefined
            };
            return normalizeLabel;
        }
    }
}

export default elasticTracking;
