<template>
  <div :data-id="item.id" class="slider-card-container">
    <div
      :data-id="item.id"
      class="slider-card ws-rounded-xl ws-shadow-lg ws-border-0 ws-overflow-hidden"
    >
      <!-- IMAGE -->
      <a
        :href="carLink"
        :target="carLinkTarget"
        rel="noopener noreferrer"
        @click="trackClick(item)"
      >
        <car-images
          :url="urlImage"
          :title="item.title"
          :sold="item.sold"
          :sale_pending="item.sale_pending"
        />
      </a>
      <!-- TITLE -->
      <h2 class="title ws-p-2 ws-mt-1 flex">
        <a
          class="ws-text-black ws-no-underline hover:ws-text-grey-darker ws-text-base ws-overflow-hidden"
          :href="carLink"
          :target="carLinkTarget"
          rel="noopener noreferrer"
          @click="trackClick(item)"
        >
          {{ item.title }}
        </a>
      </h2>
      <!-- PRICE AND KM -->
      <div class="ws-flex ws-flex-wrap">
        <div class="flex ws-w-full ws-flex-row ws-p-2">
          <div
            v-if="item.price && item.price != '' && item.price != 0"
            class="ws-flex ws-flex-row ws-items-center ws-w-full"
          >
            <div class="prices ws-w-1/2">
              {{ item.price | price }}
            </div>
            <div
              class="msrp-prices ws-w-1/2 !ws-text-lg !ws-font-bold !ws-text-gray-400 ws-line-through"
            >
              <template v-if="item.msrp_price && item.msrp_price != item.price">
                {{ item.msrp_price | price }}
              </template>
            </div>
          </div>
          <a
            class="w-full pr-1 no-underline box-title"
            :href="carLink"
            :target="carLinkTarget"
            rel="noopener noreferrer"
            @click="trackClick(item)"
            v-else
          >
            Click for Price
          </a>
        </div>
        <div
          class="extra-info ws-flex ws-flex-col ws-w-full ws-p-2 ws-pt-0 ws-min-h-[79px]"
          v-if="
            (item.miles && distance_measure == 'mile') ||
            (item.kilometer && distance_measure == 'kilometer') ||
            item.stock ||
            item.vin
          "
        >
          <span class="ws-mr-4" v-if="item.stock && item.stock.length < 32"
            ><span class="ws-font-bold">STK#</span> {{ item.stock }}</span
          >
          <span class="ws-mr-4" v-if="item.vin"
            ><span class="ws-font-bold">VIN</span> {{ item.vin }}</span
          >
          <span class="ws-mr-4" v-if="item.miles && distance_measure == 'mile'"
            ><span class="ws-font-bold">Mileage:</span>
            {{ item.miles }} mi.</span
          >
          <span
            class="ws-mr-4"
            v-if="item.kilometer && distance_measure == 'kilometer'"
            ><span class="ws-font-bold">Mileage:</span>
            {{ item.kilometer }} km.</span
          >
        </div>
        <div class="extra-info ws-flex ws-w-full ws-p-2" v-else>
          <span class="ws-mr-4">Click for more information</span>
        </div>
      </div>
      <!-- DEALER -->
      <div
        class="dealer-info ws-flex ws-cursor-pointer ws-border-gray-100 ws-border-b"
        @click="gotodealerpage(item, 'slider', 'slider_id', tracking)"
      >
        <div
          class="ws-w-full ws-h-12 ws-overflow-hidden ws-bg-no-repeat ws-bg-contain ws-bg-center ws-bg-white"
          :style="'background-image: url(' + showDealer + ')'"
        ></div>
        <div
          class="dealer-phone ws-rounded-tl-xl ws-text-sm ws-w-[50px] ws-bg-gray-100 ws-flex ws-justify-center ws-flex-col ws-p-2 ws-h-12 ws-overflow-hidden"
        >
          <p class="ws-text-black ws-text-center">
            <svg
              width="22"
              height="16"
              viewBox="0 0 22 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.7071 8.7071C22.0976 8.31658 22.0976 7.68342 21.7071 7.29289L15.3431 0.928931C14.9526 0.538406 14.3195 0.538407 13.9289 0.928931C13.5384 1.31946 13.5384 1.95262 13.9289 2.34314L19.5858 8L13.9289 13.6569C13.5384 14.0474 13.5384 14.6805 13.9289 15.0711C14.3195 15.4616 14.9526 15.4616 15.3431 15.0711L21.7071 8.7071ZM8.74228e-08 9L21 9L21 7L-8.74228e-08 7L8.74228e-08 9Z"
                fill="white"
              />
            </svg>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { price } from "../../../filters/filters";
import { gotodealerpage } from "../../modules/dealers";
import carImages from "../carImagesTodaysDrive.vue";
import { apiBaseUrl } from "../../../config";
var sha1 = require("sha1");

export default {
  created() {
    this.currentGroup = this.groupsConfig.find((o) => {
      return (o.id = this.item.group.id);
    });
  },
  computed: {
    priceTitle() {
      return this.currentGroup.fields.titleForPrice || "Dealer Price";
    },
    carLink() {
      if (this.item.by_owner === 1) {
        this.carLinkTarget = "_self";
        return "/dealer/fsbo-for-sale-by-owner";
      }
      if (this.item.user_id != null) {
        this.carLinkTarget = "_self";
        return "/cars/vdp/" + this.item.id;
      }
      let carLink = this.item.url + "?utm_source=" + this.utm_source;
      let tracking_domain =
        this.tracking_domain != "" && this.tracking_domain != undefined
          ? this.tracking_domain
          : "https://todaysdrive.aws.wehaa.net";
      return (
        tracking_domain +
        "/go.php?web=1&dealer_id=" +
        this.item.dealer.id +
        "&article_id=" +
        this.item.id +
        "&ga_id=" +
        this.ga_tracking +
        "&source=slider&url=" +
        encodeURIComponent(carLink) +
        "&vh=" +
        sha1(carLink + this.item.id + "$aZ!xGZH21")
      );
    },
    showDealer() {
      if (this.item.dealer.logo && this.item.dealer.logo != "") {
        return this.item.dealer.logo;
      }
      return false;
    },
    urlImage() {
      if (this.item.image && this.item.image != "") {
        return this.item.image;
      }

      if (this.car_default_placeholder != null) {
        return this.car_default_placeholder;
      }

      return apiBaseUrl + "/images/car.default.png";
    },
  },
  components: {
    "car-images": carImages,
  },
  props: {
    groupsConfig: {
      required: true,
      type: Array,
    },
    item: {
      required: true,
      type: Object,
    },
    utm_source: {
      required: false,
      type: String,
    },
    tracking_domain: {
      required: false,
      type: String,
    },
    ga_tracking: {
      required: false,
      type: String,
    },
    tracking: {
      required: true,
    },
    distance_measure: {
      required: false,
      default: "mile",
    },
    car_default_placeholder: {
      required: true,
      default: "/images/car.default.png",
    },
  },
  data() {
    return {
      carLinkTarget: "_blank",
      currentGroup: {},
    };
  },
  filters: {
    price,
  },
  methods: {
    sha1,
    gotodealerpage,
    trackClick(item) {
      this.tracking.sendevent("click", {
        event_category: "car",
        event_label: JSON.stringify(item),
        dealer_id: item.dealer.id,
        car_id: item.id,
        context: "slider",
        context_id: "slider_id",
      });
    },
  },
};
</script>
