import apiClient from './axiosService';

class serviceSpecialService extends apiClient {
    getServiceExtraInfo(serviceId, callback) {
        return new Promise((resolve, reject) => {
            let serviceExtraInfo = this.storage.get('serviceInfo-' + serviceId);
            if (serviceExtraInfo == undefined) {
                this.service.get('/api/v2/services/info/' + serviceId)
                    .then((response) => {
                        this.storage.set('serviceInfo-' + serviceId, response.data);
                        response.data.callback = callback;
                        resolve(response.data);
                    });
            } else {
                resolve(serviceExtraInfo);
            }
        })
    }
}

export default serviceSpecialService