function gotodealerpage(item, context, context_id, tracking, type = 'car') {
    console.log('gotodealerpage', item, context, context_id, tracking, type);
    tracking.sendevent('click', {
        event_category: 'dealer',
        event_label: JSON.stringify({
            dealer_id: item.dealer.id,
            car_id: item.id,
            context: context,
            context_id: context_id,
        }),
        dealer_id: item.dealer.id,
        car_id: item.id,
        context: context,
        context_id: context_id,
    });
    setTimeout(() => {
        window.location.href =
            '/dealer/' +
            item.dealer.slug +
            (type === 'service' ? '?searchtype=services' : '');
    }, 250);
}

export { gotodealerpage };
