<template>
    <div :data-id="item.id" class="slider-card-container">
        <div :data-id="item.id" class="slider-card slider-card-services">
            <div class="first-subtitle ws-text-white ws-bg-black ws-font-bold ws-px-2 ws-py-1 ws-uppercase ws-text-sm">{{ item.category.name }}</div>
            <!-- IMAGE -->
            <a @click="gotodealerpage(item, 'slider', 'slider_id', tracking, 'service')" class="ws-cursor-pointer">
                <car-images
                    v-if="item.images && item.images.length>0 && item.images[0]['medium-size']"
                    :url="item.images[0]['medium-size']"
                    :title="item.title"
                />
                <img v-else src="/images/car.default.png" :alt="item.title" class="ws-w-full ws-h-[225px]">
            </a>
            <!-- DEALER -->
            <div class="dealer-info ws-flex ws-cursor-pointer ws-border-gray-100 ws-border-b" @click="gotodealerpage(item, 'slider', 'slider_id', tracking, 'service')" v-if="item._dealer">
                <div class="ws-w-1/2 ws-h-12 ws-overflow-hidden ws-bg-no-repeat ws-bg-contain ws-bg-center ws-bg-white" :style="'background-image: url(' + item._dealer.logo.thumb + ')'" >
                    <!-- <img v-if="item._dealer.logo.thumb!=undefined" :src="item._dealer.logo.thumb" :alt="item._dealer.name"> -->
                </div>
                <div class="dealer-phone ws-text-sm ws-w-1/2 ws-bg-gray-100 ws-flex ws-justify-center ws-flex-col ws-p-2 ws-h-12 ws-overflow-hidden">
                    <p class="ws-text-black">Call Us</p>
                    <p class="ws-text-black ws-text-xs ws-font-bold" v-if="item._dealer.phones">{{ item._dealer.phones[0].phone }}</p>
                </div>
            </div>
            <h2 class="title ws-min-h-14 ws-p-2">
                <a class="ws-text-black ws-no-underline hover:ws-text-grey-darker ws-text-base ws-cursor-pointer" @click="gotodealerpage(item, 'slider', 'slider_id', tracking, 'service')">
                    {{ item.title }}
                </a>
            </h2>
        </div>
    </div>
</template>

<script>
import {price} from '../../filters/filters'
import {gotodealerpage} from '../modules/dealers'
import carImages from './carImages.vue'

export default {
    components: {
        'car-images': carImages,
    },
    props: {
        item: {
            required: true,
            type: Object
        },
        utm_source: {
            required: false,
            type: String
        },
        tracking: {
            required: true
        }
        
    },
    data() {
        return {
        }
    },
    filters: {
        price
    },
    methods: {
        gotodealerpage,
        trackClick(item) {
            this.tracking.sendevent('click', {
                'event_category': 'service',
                'event_label': JSON.stringify(item),
                'dealer_id': item.dealer.id,
                'car_id': item.id,
                'context': 'slider',
                'context_id': 'slider_id',
            });
        },
    },
}
</script>
