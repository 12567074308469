function price(value) {
    if (!value) return '';
    let val = (value / 1).toFixed(0).replace('.', ',');
    return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function slug(value) {
    value = value.toLowerCase();
    value = value.replace(/[á|ã|â|à]/gi, "a");
    value = value.replace(/[é|ê|è]/gi, "e");
    value = value.replace(/[í|ì|î]/gi, "i");
    value = value.replace(/[õ|ò|ó|ô]/gi, "o");
    value = value.replace(/[ú|ù|û]/gi, "u");
    value = value.replace(/[ç]/gi, "c");
    value = value.replace(/[ñ]/gi, "n");
    value = value.replace(/[á|ã|â]/gi, "a");
    value = value.replace(/\s/gi, "-");
    value = value.replace(/[^\w-]/gi, "");
    value = value.replace(/(\-)\1+/gi, "-");
    return value;
}

export {price, slug}