import apiClient from './axiosService';

class sliderService extends apiClient {
    getSliderConfig(sliderId, callback) {
        return new Promise((resolve, reject) => {
            let sliderConfig = this.storage.get('sliderConfig-' + sliderId)
            if (sliderConfig == undefined) {
                this.service.get('/api/v2/sliders/' + sliderId)
                    .then((response) => {
                        this.storage.set('sliderConfig-' + sliderId, response.data)
                        response.data.callback = callback
                        resolve(response.data)
                    })
            } else {
                resolve(sliderConfig)
            }
        })
    }
}

export default sliderService