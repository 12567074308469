import Vue from 'vue'
import Slider from './components/Slider.vue'
// import router from './router'
// import store from '../store/index'

import 'document-register-element/build/document-register-element'

import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

// Slider.store = store
// Slider.router = router
Vue.customElement('wehaa-slider', Slider, {})