<template>
  <div
    class="ws-w-full ws-h-[225px] ws-bg-no-repeat ws-bg-contain ws-bg-[top_center] ws-bg-gray-50"
    :style="`background-image: ${backgroundUrl}`"
    aria-role="img"
  >
    <span class="sr-only">Car image</span>
  </div>
</template>

<script>
export default {
  name: "car-images",
  props: {
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    sold: {
      type: Boolean,
      default: false,
    },
    sale_pending: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    backgroundUrl() {
      let car_image = "url(" + this.url + ")";
      let sold_image = "";
      let sale_pending_image = "";
      if (this.sold) {
        sold_image =
          "url(https://wehaa-dealers-common.s3.us-east-2.amazonaws.com/media-library/2-sold.png), ";
      }
      if (this.sale_pending) {
        sale_pending_image =
          "url(https://wehaa-dealers-common.s3.us-east-2.amazonaws.com/media-library/2-pending.png), ";
      }
      return sold_image + sale_pending_image + car_image;
    },
  },
};
</script>
