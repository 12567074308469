<template>
    <div
        class="ws-w-full ws-h-[225px] ws-bg-no-repeat ws-bg-contain ws-bg-center ws-bg-gray-50"
        :style="`background-image: ${backgroundUrl}`"
        aria-role="img"
    >
        <span class="sr-only">Car image</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            backgroundUrl: ''
        };
    },
    mounted() {
        this.loadBackgroundImage();
    },
    methods: {
        loadBackgroundImage() {
            const img = new Image();
            img.onload = () => {
                this.setBackgroundImage('url(' + this.url + ')');
            };
            img.onerror = () => {
                if (window.location.hostname.includes('hanks')) {
                    this.setBackgroundImage(
                        'url(https://wehaa-dealers-common.s3.us-east-2.amazonaws.com/media-library/2-1710942866-car.default.png)'
                    );
                }
            };
            img.src = this.url;
        },
        setBackgroundImage(imageUrl) {
            let sold_image = '';
            let sale_pending_image = '';
            if (this.sold) {
                sold_image = 'url(https://wehaa-dealers-common.s3.us-east-2.amazonaws.com/media-library/2-sold.png), ';
            }
            if (this.sale_pending) {
                sale_pending_image =
                    'url(https://wehaa-dealers-common.s3.us-east-2.amazonaws.com/media-library/2-pending.png), ';
            }
            this.backgroundUrl = sold_image + sale_pending_image + imageUrl;
        }
    },
    name: 'car-images',
    props: {
        url: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: ''
        },
        sold: {
            type: Boolean,
            default: false
        },
        sale_pending: {
            type: Boolean,
            default: false
        }
    }
};
</script>
